<template>
  <!-- <div class="px-md-5 px-1 mt-5">
        <div class="d-flex flex-wrap">
            <div class="content-left col-lg-3 col-sm-12" style="">
                <div style="; background-color: var(--f-main); border-radius: 15px " class="d-flex justify-content-between p-3">
                    <div class="position-relative">
                        <img :src="user_info.avatar" width="100px" height="100px" alt="">
                        <div class="bg_dark_blue  p-2 text-center position-absolute bottom-0 right-0 left-0
              cursor-pointer">

                            <el-upload
                                    class=""
                                    :action="`*`"
                                    :before-upload='beforeUpload'
                            >
                                <div size="small" type="primary" style="font-size: 12px">点击更换头像</div>
                            </el-upload>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-around align-items-end">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <div class="mr-5"><span class="text-overflow-1"><money/>{{user_info.bean}}</span><span style="font-size: 12px" class="op-6">余额</span></div>
                            <div class=""><span  class="text-overflow-1">{{user_info.integral}}</span><span style="font-size: 12px" class="op-6">积分</span></div>
                        </div>
                        <div class="ml-auto  " style="position: relative;z-index: 999">
                            <div class="mr-3 d-none">
                                <Task-in :bottom-right="false">
                                    <div class="f_btn3 px-3 py-1 rounded cursor-pointer">
                                        <div>
                                            签到
                                        </div>
                                    </div>
                                </Task-in>
                            </div>
                            <router-link to="/user/recharge" class="f_btn3 px-md-5 px-3 py-1 rounded cursor-pointer">
                                充值
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="mt-3 px-2">
                    <div class="h3 font-weight-bold text-white">{{name}}</div>
                    <div class="op-6 " style="font-size: 12px">用户ID：{{user_info.id}}</div>
                </div>
            </div>

            <div class="ml-auto content-right px-2 col-lg-9 col-sm-12" style="">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="账户设置" name="user">
                        <div class="h5 mt-3 font-weight-bold d-md-block d-none">账户设置</div>
                        <div class="d-md-flex row mt-3">
                            <div class=" mr-0 col-md-3 col-12">
                                <div @click="user.info = 1" :class="user.info == 1? 'f-yellow-color' :''"
                                     class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">个人资料
                                </div>
                                <div @click="user.info = 2" :class="user.info == 2? 'f-yellow-color' :''"
                                     class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">
                                    交易报价链接
                                </div>
                                <div @click="user.info = 4" :class="user.info == 4? 'f-yellow-color' :''"
                                     class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">快速帮助
                                </div>
                            </div>
                            <div v-if="user.info == 1" class="col-md-9 p-0 pb-5"
                                 style="border: 3px solid var(--f-main); background-color: var(--main-bg-2) " name="个人资料">
                                <div class="h5 mb-0 font-weight-bold p-3 w-100"
                                     style="background-color: var(--f-main) ">个人资料
                                </div>
                                <div class="w-100 px-3 " style="">
                                    <div class="d-flex mt-3 d-block justify-content-between align-items-center" style="height: 50px">
                                        <div>修改昵称</div>
                                        <div>
                                 <span class="mr-3 pb-2" style="border-bottom:1px dashed var(--main-blue);">
                        <input style="width: 150px" v-model="name" class="text-white"
                               type="text"
                               placeholder="输入昵称"></span>

                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(--main-blue)"
                                                  @click="set_name">保存</span>
                                        </div>
                                    </div>

  绑定邀请码  
                                    <div class="d-flex mt-3 d-block justify-content-between align-items-center" style="height: 50px">
                                        <div>邀请码<span></span></div>
                                        <div v-if="user_info.inviter != null">
                                            <span class="mr-3 pb-2" style="border-bottom:1px dashed var(--main-blue);">
                                                <span style="width: 150px" class="text-white">{{user_info.inviter.invite_code}}</span>
                                                       <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                      style="border:1px solid var(--main-blue);margin-left:30px">已绑定</span>
                                            </span>

                                        </div>
                                        <div v-else>
                                            <span class="mr-md-3 mr-1 pb-2" style="border-bottom:1px dashed var(--main-blue);">
                                                <input  style="width: 150px" v-model="invite_code" class="text-white"
                                                       type="text"
                                                       placeholder="输入邀请码">


                                                <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                      style="border:1px solid var(--main-blue)"
                                                      @click="getSet_inviter">绑定</span>
                                            </span>
                                        </div>



                                    </div>




                                    <div class="align-items-center mt-3 d-flex  justify-content-between" style="height: 50px">
                                        <div>账户手机</div>
                                        <div class="">
                                            <span class="mr-3 pb-2" style=" width: 150px;border-bottom:1px dashed var(--main-blue);">
                                                {{user_info.mobile}}</span>
                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(--main-blue)" @click="changePassword">更新</span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt-3  justify-content-between align-items-center" style="height: 50px">
                                        <div>账户控制</div>
                                        <div class="text-right">
                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(--main-blue)"
                                                  @click="loginOut">登出</span>
                                        </div>
                                    </div>






                                </div>
                            </div>
                            <div v-if="user.info == 2" class="col-md-9 p-0 pb-5"
                                 style="border: 3px solid var(--f-main); background-color: var(--main-bg-2) " name="交易报价链接">
                                <div class="h4 mb-0 font-weight-bold p-3 w-100 "
                                     style="background-color: var(--f-main)">
                                    交易报价链接

                                        <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" style="font-size: 0.8rem; color: var(--main-blue)" target="_blank">点我获取交易链接</a>


                                </div>
                                <div class="p-4">
                                    <input class="input py-4 px-4 " v-model="steam_url"
                                           type="text" placeholder="交易报价链接 ">
                                </div>
                                <div class="d-flex justify-content-end align-items-center pb-3 px-3">


                                    <div>
                                        取消
                                    </div>
                                    <div class="f-main-bg px-5 py-2 text-center rounded ml-3"
                                         @click="getSteamLink">
                                        保存
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>
                            <div v-if="user.info == 4" class="col-md-9 p-0 pb-5"
                                 style="border: 3px solid var(--f-main); background-color: var(--main-bg-2) " name="社交资料">
                                <div class="h4 mb-0 font-weight-bold p-3 w-100 "
                                     style="background-color: var(--f-main)">
                                    快速帮助
                                </div>
                                <div class="px-4 d-flex justify-content-between mt-4 ">
                                    <div>网站客服</div>
                                    <div class="col-md-8 col-12">该客服处理充值、提货问题 QQ {{web_info.qq_group_number}} (10:00-22:00)</div>
                                </div>
                                <div class="px-4 d-flex justify-content-between mt-4">
                                    <div>一般问题</div>
                                    <div class="col-md-8 col-12">该客服处理您在网站使用中的各种问题。QQ {{web_info.qq_group_number}} (10:00-22:00)</div>
                                </div>
                                <div class="px-4 d-flex justify-content-between mt-4">
                                    <div>提货帮助</div>
                                    <div class="col-md-8 col-12"><a href="/active/help/5" style="text-decoration-line:underline">如何提取您的装备和需要注意的事项？</a></div>
                                </div>
                           
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="个人仓库" name="back"></el-tab-pane>
                    <el-tab-pane label="取回历史" name="old">
                        <storage-list  :Storage-list-list="StorageList" :storage-listpage-size="StorageListpageSize"
                                      :storage-listtotal="StorageListtotal"></storage-list>
                    </el-tab-pane>
                    <el-tab-pane label="充值历史" name="pay">
                        <pay :table-data="tableData" :total="total" :page-size="pageSize" ></pay>
                    </el-tab-pane>
                    <el-tab-pane label="收支明细" name="income">
                        <income :table-data="records" :total="recordstotal" :page-size="recordspageSize" ></income>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>

          验证码需要登录的那个弹窗 
        <el-dialog
                custom-class="login_egister"
                :show-close="false"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :visible.sync="passwordShow"
                width="500px">
            <div class="login d-flex py-5 px-3">
                <div class="login-right">

                     验证码 
                    <div class="d-flex border-dark-brown align-items-center mt-3">
                        <div class="bg-bg-2 px-3 py-2 login-right-input">

                            <div>
                                <i class="iconfont text-white icon-dunpai  cursor-pointer" style="font-size:26px"></i>
                            </div>
                        </div>
                        <div class="ml-2">
                            <input v-model="form.verify" class="text-white d-block password"
                                   style="font-size: 16px;" type="text" placeholder="请输入验证码">
                        </div>
                        <div @click="getCode" :class="getCodeSeond != '获取验证码'? 'cursor-not' :''"
                             class=" bg-bg-2 px-3 ml-auto color-yellow cursor-pointer"
                             style="height: 42px;line-height: 40px">
                            <div>
                                {{getCodeSeond}}
                            </div>
                        </div>
                    </div>

                     密码 - 注册 
                    <div class="d-flex border-dark-brown align-items-center mt-3">
                        <div class="bg-bg-2 px-3 py-2 login-right-input">

                            <div>
                                <i class="iconfont text-white icon-mima  cursor-pointer" style="font-size:26px"></i>
                            </div>
                        </div>
                        <div class="ml-2">
                            <input v-model="form.password" class="text-white d-block"
                                   style="font-size: 16px;width: 200px" type="password" placeholder="请输入密码">
                        </div>
                    </div>

                     用户登录和steam登录 
                    <div class="d-flex justify-content-center align-items-center">
                        <bottom-bg @click.native="changePasswordClick" :title="'确认修改'" class="mt-3 px-5"></bottom-bg>
                    </div>
                </div>
                <div @click="passwordShow = false" class="position-absolute" style="right: 30px;top: 20px">
                    <div>
                        <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>
            </div>
        </el-dialog>
         vueCropper 剪裁图片实现
        <el-dialog class="cropper-dialog" width="600px" :close-on-click-modal="false" :visible.sync="dialogVisible"
                   append-to-body>
            <div class="cropper-content d-md-flex d-block justify-content-center align-items-center">
                <div class="cropper" style="text-align:center">
                    <vueCropper
                            @real-time="realTime"
                            ref="cropper"
                            :img="option.img"
                            :outputSize="option.size"
                            :outputType="option.outputType"
                            :info="true"
                            :full="option.full"
                            :canMove="option.canMove"
                            :canMoveBox="option.canMoveBox"
                            :original="option.original"
                            :autoCrop="option.autoCrop"
                            :auto-crop-width="option.autoCropWidth"
                            :auto-crop-height="option.autoCropHeight"
                            :fixed="option.fixed"
                            :fixedNumber="option.fixedNumber"
                            :centerBox="option.centerBox"
                            :infoTrue="option.infoTrue"
                            :fixedBox="option.fixedBox"
                    ></vueCropper>
                </div>
                <div class="cropper d-none justify-content-between align-items-center text-center">
                    <div>
                        <div class="show-preview"
                             :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden', 'margin': '5px'}">
                            <div :style="previews.div">
                                <img width="100px" height="100px" :src="previews.url" :style="previews.img">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="show-preview rounded-pill"
                             :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden', 'margin': '5px'}">
                            <div :style="previews.div">
                                <img class="" width="100px" height="100px" :src="previews.url" :style="previews.img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer d-flex justify-content-center">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
            </div>
        </el-dialog>
    </div> -->
  <div class="user-center-container" style="margin-top: 20px">
    <div class="user-profile">
      <img class="user-img" :src="user_info.avatar" alt="" />
      <span class="user-name">{{ name }}</span>
      <span class="user-level"> LV{{ user_info.vip_level }}</span>
      <img class="setting" src="@/assets/image/userCenter/setting_icon.png" alt="" @click="goSetting" />
    </div>
    <div class="user-pay-box">
      <div class="pay-tips">
        <div class="pay-word">充值中心</div>
        <div class="pay-discount">限时首充赠送最高20%</div>
      </div>
      <img class="pay-icon" src="@/assets/image/userCenter/pay_icon.png" alt="" @click="goVoucherCenter" />
    </div>
    <div class="package-box">
      <div class="package-left" @click="goPacksack">
        <img src="@/assets/image/userCenter/package.png" alt="" />
        <span>我的背包</span>
      </div>
      <div class="package-left" @click="goShopping">
        <img src="@/assets/image/userCenter/bags.png" alt="" />
        <span>饰品商城</span>
      </div>
    </div>
    <ul class="operate-detail">
      <li class="operate-item" v-for="(item, index) in operateLinks" :key="index + '0'" @click="handle(item)">
        <img :src="item.icon" alt="" />
        <div class="operate-name">{{ item.title }}</div>
      </li>
    </ul>
    <ul class="certificate-box">
      <li class="certificate-item">
        <span @click="certificateTip">实名认证</span><img src="@/assets/image/userCenter/arrow_icon.png" />
      </li>
      <li class="certificate-item">
        <span @click="goSetting">账号设置</span><img src="@/assets/image/userCenter/arrow_icon.png" />
      </li>
      <li class="certificate-item">
        <span @click="loginOut">退出登录</span><img src="@/assets/image/userCenter/arrow_icon.png" />
      </li>
    </ul>
    <!-- 交易链接弹窗 -->
    <div class="deal-dialog" v-if="deallink">
      <div class="bind-link family">绑定steam链接</div>
      <div class="code">
        <input type="text" v-model="steam_url" placeholder="交易链接" />
        <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" class="get-code"
          target="_blank">获取</a>
      </div>
      <button @click="getSteamLink">绑定</button>
      <van-icon name="cross" @click="deallink = false" class="icon-cross" />
    </div>
    <!-- CDK红包弹窗 -->
    <div v-show="immediatelyShopping" class="position-fixed z-index-1" style="
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
      ">
      <div v-show="immediatelyShopping" class="position-fixed z-index-100 immediatelyShopping">
        <div class="bg-size bg-repeat" :style="`background-image: url(${require('@/assets/img/vd/red_top.png')})`">
          <div class="immediatelyShopping-box px-2 pt-3">
            <div class="d-flex"></div>
            <div class="text-center mt-1" style="font-size:16px;">
              加官群 {{ web_info.qq_group_number }}，抢更多福利！
            </div>
            <div class="d-flex justify-content-center px-3 immediatelyShopping-input">
              <input placeholder="请输入红包口令" type="text" v-model="packet" class="d-block"
                style="color: black;font-size:12px;padding:10px;" />
            </div>
            <div class="d-flex justify-content-center">
              <img @click="OpenRebBack(2)" width="60px" :src="require('@/assets/img/vd/kai.png')" alt="" />
            </div>
            <div class="mt-4 text-center">红包列表</div>
            <div class="immediatelyShopping-list mt-2">
              <div :class="item.status != 2 ? '' : 'filter-grayscale-1'" v-for="(item, index) in redList" :key="index"
                class="px-2 py-1 rounded mb-2" style="background-color: #ad252c">
                <div class="d-flex mt-3">
                  <div
                    class="immediatelyShopping-item-img d-flex flex-column align-items-center justify-content-end bg-size-100 pb-md-2 pb-1"
                    :style="`background-image: url(${require('@/assets/img/vd/red_money.png')})`" style="font-size: 12px">
                    <div>剩余数量</div>
                    <div>{{ item.num }}</div>
                  </div>
                  <div class="ml-2" style="font-size: 13px">
                    <div class="mb-2">{{ item.title }}</div>
                    <div class="mb-2">参与条件：{{ item.briefe }}</div>
                    <div class="mb-2">结束时间：{{ item.end_time }}</div>
                  </div>
                </div>
                <div @click="OpenRebBack(1, item)" class="cursor-pointer immediatelyShopping-pk text-center px-4">
                  抢红包
                </div>
              </div>
            </div>
            <div @click="immediatelyShopping = false;" class="position-absolute" style="right: 0; top: 0">
              <i class="iconfont icon-guanbi-02-02 text-white" style="font-size: 30px"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bottomBg from "@/components/bottomBg";
import pay from "./common/pay";
import income from "./common/income";
import StorageList from "./common/StorageList";
import TaskIn from "@/views/TaskIn/index";
import $api from '@/api/user/index';
import { USER_OPERATE_LINK } from "@/data/userCenter.js";
import { Toast } from "vant";
export default {
  name: "index",
  components: {
    StorageList,
    bottomBg,
    pay,
    income,
    TaskIn,
  },
  data() {
    return {
      activeName: "user",
      tableData: [],
      StorageList: [],
      user: {
        info: 1,
      },
      passwordShow: false,
      getCodeSeond: "获取验证码",
      InvitationCode: "",
      steam_url: "",
      form: {
        mobile: "",
        password: "",
        verify: "",
      },
      invite_code: "",
      inviter: "",
      name: "",
      dialogVisible: false,
      loading: false,
      option: {
        canMove: true,
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 100, // 默认生成截图框宽度
        autoCropHeight: 100, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      previews: {},

      total: 0,
      StorageListtotal: 0,
      pageSize: 0,
      StorageListpageSize: 0,
      old: "",
      records: [],
      recordstotal: 0,
      recordspageSize: 0,
      operateLinks: USER_OPERATE_LINK,
      value: "",
      deallink: false,
      immediatelyShopping: false,
      packet: "",
      redList: [],
      silde: 0,
      isShowSidle: false,
      sidleSum: 0,
    };
  },
  computed: {
    money() {
      let benr = 0;
      this.StorageList.forEach((item) => {
        benr += item.bean * 1;
      });
      return benr.toFixed(2);
    },
    recyleMoney() {
      let benr = 0;
      this.StorageList.forEach((item) => {
        if (item.isShow == true) {
          benr += item.bean * 1;
        }
      });
      return benr.toFixed(2);
    },
    ...mapState(["user_info", "web_info"]),
  },
  created() {
    let { name } = this.$route.query;
    if (name) {
      this.activeName = name;
    }
    setTimeout(() => {
      this.name = this.user_info.name;
      this.steam_url = this.user_info.steam_url;
      this.InvitationCode = this.user_info.invite_code;
    }, 500);
  },
  mounted() {
    this.getRecharge_record();
    this.getUsetChange_records();
  },
  methods: {
    tabClickBack() {
      console.log("asdsada");
    },
    loginOut() {
      this.$store.commit("LOGIN_IS_SHOW", true);
      this.$store.dispatch("removeInfo");
      this.$router.push("/");
    },
    handleClick(tab, event) {
      console.log("tab, event");
      console.log(tab, event);
      if (tab.name == "back") {
        this.$router.push("/user/info");
      }
      if (tab.name == "old") {
        this.getStorage();
      }
    },
    async getStorage(page = 1, status = 1) {
      try {
        let { data, code } = await $api.getStorage(page, status);
        if (code == 500) return;
        data.data.forEach((item) => {
          if (item.back_message != "") {
            console.log(123);
          }
          item.isShow = false;
        });
        this.StorageList = data.data;
        this.StorageListtotal = data.total;
        this.StorageListpageSize = data.per_page;
      } catch (e) {
        console.log(e);
      }
    },
    async getRecharge_record(page = 1) {
      try {
        let { data, code } = await $api.getRecharge_record(page);
        if (code == 500) return;
        this.tableData = data.data;
        this.total = data.total;
        this.pageSize = data.per_page;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async getUsetChange_records(page = 1) {
      try {
        let { data, code } = await $api.getUsetChange_records(page);
        if (code == 500) return;
        this.records = data.data;
        this.recordstotal = data.total;
        this.recordspageSize = data.per_page;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    currentChangeIncome(val) {
      this.getUsetChange_records(val);
      window.scrollTo(0, 0);
    },
    currentChange(val) {
      this.getStorage(val);
      window.scrollTo(0, 0);
    },
    async recycle() {
      let arr = [];
      try {
        this.StorageList.forEach((item) => {
          if (item.isShow) {
            arr.push(item.id);
          }
        });
        let { message, code } = await $api.getCash({ data: arr });
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
        }

        this.getStorage();
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
    async extract() {
      let arr = [];
      this.StorageList.forEach((item) => {
        if (item.isShow) {
          arr.push(item.id);
        }
      });
      try {
        let { message, code } = await $api.getExtract({ data: arr });
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
        }
        this.getStorage();
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
    itemOne(item) {
      if (item.back_message != "") {
        this.ReturnReason = true;
        this.back_message = item.back_message;
      }
      item.isShow = !item.isShow;
    },
    changePassword() {
      this.passwordShow = true;
    },
    async changePasswordClick() {
      try {
        this.form.mobile = this.user_info.mobile;
        let { message } = await $api.getpassword(this.form);
        this.$notify({
          message: message,
          position: "top-left",
        });
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async getSet_inviter() {
      try {
        let { message, code } = await $api.getSet_inviter({
          invite_code: this.invite_code,
        });
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
        }
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async getCode() {
      let data = await $api.getSendSms({
        mobile: this.user_info.mobile,
        is_use: 2,
      });
      if (data.code == 500) return;
      this.$notify({
        title: "成功",
        message: data.message,
        type: "success",
        position: "top-left",
      });
      this.getCodeSeond = 60;
      let time = setInterval(() => {
        this.getCodeSeond -= 1;
        if (this.getCodeSeond == 0) {
          this.getCodeSeond = "获取验证码";
          clearInterval(time);
        }
      }, 1000);
    },
    async userSrc(response) {
      try {
        this.$common.blobToDataURL(response, async (res) => {
          let { data } = await $api.getImageBase64({ data: res });
          let { message, code } = await $api.getSet_avatar({ path: data.url });
          if (code == 500) return;
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
          this.$store.dispatch("userInfo");
        });
      } catch (e) {
        console.log(e);
        return;
      }
    },
    authCopy() {
      console.log(this.InvitationCode);
      this.$common.CopyText({ content: this.InvitationCode });
    },
    async getSteamLink() {
      try {
        let { message, code } = await $api.getSet_steam_url({
          steam_url: this.steam_url,
        });
        if (code == 500) return;
        this.$notify({
          title: "成功",
          message: message,
          type: "success",
          position: "top-left",
        });
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async set_name() {
      try {
        let { message, code } = await $api.getSet_name({ name: this.name });
        if (code == 500) return;
        this.$notify({
          title: "成功",
          message: message,
          type: "success",
          position: "top-left",
        });
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
    async beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isImage = file.type.indexOf("image");
      if (!isLt5M) {
        this.$notify({
          message: "图片大于5M,请压缩图片",
        });
        return false;
      }
      if (isImage === -1) {
        this.$notify({
          message: "请上传图片格式,jpg或者png",
          position: "top-left",
        });
        return false;
      }
      this.$common.blobToDataURL(file, async (res) => {
        this.$nextTick(() => {
          this.option.img = res;
          this.previews.url = res;
          this.dialogVisible = true;
        });
      });
      return false;
    },
    finish() {
      this.$refs.cropper.getCropData(async (data) => {
        let { data: url } = await $api.getImageBase64({ data: data });
        let { message } = await $api.getSet_avatar({ path: url.url });
        this.$notify({
          title: "成功",
          message: message,
          type: "success",
          position: "top-left",
        });
        this.$store.dispatch("userInfo");
        setTimeout(() => {
          this.dialogVisible = false;
          this.loading = false;
        }, 1000);
      });
    },
    realTime(data) {
      this.previews = data;
    },
    goSetting() {
      this.$router.push({ name: "accountSetting" });
    },
    goVoucherCenter() {
      this.$router.push({ name: "recharge" });
    },
    goPacksack() {
      this.$router.push({ name: "packsack" });
    },
    goShopping() {
      this.$router.push({ name: "shopping" });
    },
    //实名认证提示
    certificateTip() {
      Toast("此功能正在开发中...");
    },
    logout() {
      this.$router.push({ name: "index" });
    },
    handle(item) {
      if (item.value == 1) {
        this.deallink = true;
      }
      if (item.value == 2) {
        this.$router.push({ name: "recharges" });
      }
      if (item.value == 3) {
        this.$router.push({ name: "promote" });
      }
      if (item.value == 4) {
        this.$router.push({ name: "account" });
      }
      if (item.value == 5) {
        this.$router.push({ name: "helpcenter" });
      }
      if (item.value == 6) {
        this.$router.push({ name: "memberVip" });
      }
      if (item.value == 7) {
        this.immediatelyShopping = true;
      }
    },
    async getredList() {
      let { data, code } = await $api.getRebBackList();
      if (code != 200) return;
      this.redList = data;
    },
    // 打开红包
    async OpenRebBack(sum, item) {
      let redOpen = {};
      if (sum == 1) {
        redOpen = {
          type: sum,
          id: item.id,
        };
      }
      if (sum == 2) {
        redOpen = {
          type: sum,
          key: this.packet,
        };
      }
      try {
        let { data, code } = await $api.getOpenRebBack(redOpen);
        if (code != 200) return;
        this.$confirm("您领取了，价值" + data + "的红包", "领取", {
          customClass: "redPacket",
          confirmButtonText: "确定",
          type: "success",
        }).then(() => {
          this.$message({
            type: "success",
            message: "领取成功!",
          });
        });

        this.$notify({
          message: data,
          type: "success",
          position: "top-left",
        });
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* .content-left {
    width: 500px;

    @media (max-width: 1200px) {
        width: 100%;
    }
}

.content-right {
    width: calc(100% - 530px);

    @media (max-width: 1200px) {
        width: 100%;
    }
}

.record {
    width: 100%;
    background-color: var(--main-bg-2);
    padding: 20px;
}

.user_bottom {
    color: var(--main-yellow);
    position: relative;

    &::before {
        content: "";
        position: absolute;
        bottom: -1px;
        background-color: var(--main-yellow);
        left: 0;
        right: 0;
        height: 3px;
    }
}

.password {
    width: 200px;

    @media (max-width: 1200px) {
        width: 100px;
        font-size: 12px;
    }
}

.setam-link {
    width: 500px;

    @media (max-width: 1200px) {
        width: 300px;
    }

    @media (max-width: 960px) {
        width: 200px;
    }
}

.title-phone {
    @media (max-width: 1200px) {
        display: none;
    }
}

.cropper-content {
    & .cropper {
        width: 300px;
        height: 300px;
    }
}

.side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
}

.content {
    width: 50%;
    margin: 0 auto;

    @media (max-width: 1200px) {
        width: 96%;
        font-size: 12px;
    }
}

.input-height {
    height: 38px;
    line-height: 38px;
    width: 150px;
    text-align: center;
}

.input-width {
    width: 300px;

    @media (max-width: 1200px) {
        width: 150px;
    }
}

.login {
    &-left {
        margin-left: 30px;
        width: 170px;
    }

    &-right {
        margin-right: 30px;
        width: 770px;

        &-input {
            color: #FFFFFF;
            font-size: 25px;
        }
    }
} */
.user-center-container {
  width: 100%;
  padding: 0 16px;
  /* height: calc(100% - 200px); */

  /* .logo-box {
        padding: 0 6px;
        height: 44px;
        display: flex;
        justify-content: space-between;

        .logo-left {
            width: 110px;
            height: 100%;
        }

        .logo-right {
            font-size: 12px;
            width: 115px;
            height: 100%;
            background-image: url("../../assets/image/userCenter/logo_right_bg.png");
            background-size: 100% 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 20px;

            img {
                width: 14px;
                height: 14px;
            }

            .u8-icon {
                span {
                    display: inline-block;
                    height: 14px;
                    transform: translateY(-2px);
                }
            }
        }
    } */

  .user-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 18px;
    margin-top: 27px;
    height: 62px;

    .user-img {
      float: left;
      width: 44px;
      height: 44px;
      //margin-left: 14px;
      border-radius: 50%;
    }

    .user-name {
      //float: left;
      //display: inline-block;
      //margin-left: 12px;
      //line-height: 62px;
      font-size: 14px;
    }

    .user-level {
      font-style: italic;
      //margin-left: 14px;
      //float: left;
      display: inline-block;
      line-height: 28px;
      //margin-top: 17px;
      text-align: center;
      width: 78px;
      height: 28px;
      border: 2px solid #009fff;
      border-radius: 20px;
      background: linear-gradient(125deg, #009fff 0%, #05377b 100%);
      /* box-shadow: inset 1px 1px 0px 0px rgba(255, 255, 255, 0.5), 2px 5px 8px 0px #000000; */
      font-size: 13px;
      font-weight: bold;
      color: #009fff;
    }

    .setting {
      //float: right;
      width: 25px;
      height: 25px;
      //margin-top: 18px;
    }
  }

  .user-pay-box {
    //margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 89px;
    background-image: url("../../assets/image/userCenter/pay_bg.png");
    background-size: 100% 100%;
    padding: 18px 10px 18px 18px;

    .pay-tips {
      .pay-word {
        font-style: italic;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 6px;
        -webkit-text-stroke: 0.5px rgba(159, 218, 53, 1);
        color: transparent;
      }

      .pay-discount {
        font-size: 13px;
        color: #9fda35;
        line-height: 17px;
      }
    }

    .pay-icon {
      width: 58px;
      height: 58px;
    }
  }

  .package-box {
    margin: 10px auto; 
    display: flex;
    justify-content: space-between;
    align-items: center;

    .package-left {
      width: 170px;
      height: 60px;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
      // background-image: url("../../assets/image/userCenter/package_bg.png");
      // background-size: 100% 100%;
      padding: 15px 28px;
      background-color: #035ead;
      border-radius: 15px;

      img {
        width: 30px;
        height: 30px;
      }

      span {
        font-style: italic;
        margin-left: 25px;
        display: inline-block;
        height: 30px;
        transform: translateY(-9px);
        /* line-height: 30px; */
      }
    }
  }

  .operate-detail {
    height: 175px;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
    margin: 10px auto;
    font-style: italic;
    color: #ffffff;
    // background-image: url("../../assets/image/userCenter/operate_link_bg.png");
    // background-size: 100% 100%;
    font-size: 12px;
    background-color: #035ead;
    border-radius: 15px;

    .operate-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      //text-align: center;
      width: 25%;

      img {
        width: 30px;
        height: 30px;
      }

      .operate-name {
        width: 100%;
        text-align: center;
        margin-top: 14px;
      }

      &:nth-child(5) {
        justify-content: flex-end;
      }

      &:nth-child(6) {
        justify-content: flex-end;
      }

      &:nth-child(7) {
        justify-content: flex-end;
      }
    }
  }

  .certificate-box {
    width: 100%;
    margin: 10px auto;
    font-size: 13px;

    .certificate-item {
      margin-bottom: 10px;
      width: 100%;
      height: 50px;
      // background-image: url("../../assets/image/userCenter/certificate_bg.png");
      // background-size: 100% 100%;
      background-color: #035ead;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      padding: 0 5px 0 28px;
      font-style: italic;
      font-weight: bold;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  /* 交易链接弹窗样式 */
  .deal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 215px;
    background: rgba(5, 55, 123, 0.6);
    border-radius: 10px;
    backdrop-filter: blur(3px);

    .bind-link {
      margin: 47px 81px 10px 81px;
      width: 137px;
      height: 25px;
      font-size: 20px;
      -webkit-text-stroke: 1px white;
      color: RGBA(23, 116, 164, 1);
    }

    .code {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px auto;
      width: 228px;
      height: 45px;
      border-radius: 10px;
      opacity: 1;
      border: 1px solid #9fda35;
      padding-left: 10px;
      font-size: 14px;

      input[type=text]::placeholder {
        color: #fff;
      }

      .get-code {
        color: #9fda35;
        margin-right: 10px;
      }
    }

    button {
      width: 228px;
      height: 38px;
      margin: 14px 36px 14px 36px;
      background-color: #009fff;
      text-align: center;
      line-height: 38px;
      border-radius: 10px;
      opacity: 1;
      border: 1px solid #009fff;
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
    }

    .icon-cross {
      position: absolute;
      top: 30px;
      left: 264px;
    }
  }

  /* 红包弹窗样式 */
  .cdk-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 331px;
    height: 413px;
    // background-image: url(../../assets/image/2.png);
    background-size: cover;

    .icon-cross {
      margin-top: 17px;
      margin-left: 289px;
    }

    input[type="text"]::placeholder {
      color: white;
    }

    input {
      display: block;
      width: 228px;
      height: 38px;
      border-radius: 10px;
      text-align: center;
      margin: 108px auto;
      background-color: RGBA(111, 15, 36, 1);
      font-size: 16px;
    }
  }
}

.immediatelyShopping {
  width: 350px;
  left: calc(50% - 175px);
  top: calc(25%);
  z-index: 999;
  background-color: #c02932;
  border-radius: 10px;

  &-list {
    height: 300px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-input {
    width: 150px;
    margin: 10px auto;
    background-color: #fffad8;
    border-radius: 5px;

    & input {
      width: 140px;
    }
  }

  &-item {
    &-img {
      width: 150px;
      height: 80px;
    }
  }

  &-pk {
    width: 100%;
    margin: 5px auto;
    background-color: #bd3840;
    padding: 10px;
    border-radius: 5px;
  }

  @media (max-width: 1200px) {
    width: 70%;
    left: 15%;
    top: calc(80px + 5%);

    &-box {
      &-con {
        &-img {
          width: 50%;
        }

        & img {
          width: 100%;
        }

        &-dis {
          margin-left: 20px !important;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
