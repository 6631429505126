export const USER_OPERATE_LINK = [
  {
    title: '交易链接',
    icon: require('@/assets/image/userCenter/user_link_trade.png'),
    routerName: "",
    value:1
  },
  {
    title: '充值记录',
    icon: require('@/assets/image/userCenter/user_link_pay.png'),
    routerName: "recharge",
    value:2
  },
  {
    title: '推广记录',
    icon: require('@/assets/image/userCenter/user_link_recommend.png'),
    routerName: "promote",
    value:3
  },
  {
    title: '账户记录',
    icon: require('@/assets/image/userCenter/user_link_account.png'),
    routerName: "accountSetting",
    value:4
  },
  {
    title: '帮助中心',
    icon: require('@/assets/image/userCenter/user_link_help.png'),
    routerName: "accountSetting",
    value:5
  },
  {
    title: 'VIP会员',
    icon: require('@/assets/image/userCenter/user_link_fair.png'),
    routerName: "accountSetting",
    value:6
  },
  // {
  //   title: 'CDK红包',
  //   icon: require('@/assets/image/userCenter/user_link_CDK.png'),
  //   routerName: "accountSetting",
  //   value:7
  // },
  {
    title: ' 敬请期待',
    icon: require('@/assets/image/userCenter/user_link_package.png'),
    routerName: "",
    value:8
  },

]